import React from 'react'

import Accordion from 'component/basic/Accordion'

const ScriptEnvs101 = () => {

  const [ expanded, setExpanded ] = React.useState(false);

  return (
    <>
      <h3>package.json Script envs 101</h3>
      <Accordion expanded={expanded} setExpanded={setExpanded} id="1" 
      header = 'package.json | scripts'
      content = 'bring in env params without 3rd party tools'
      code = {`
      "start": "env $(cat .env | grep -v \\"#\\" | xargs) node src/index.js"
        `}
      />

      {}

      
    </>
  )
}

export default ScriptEnvs101